@import url(https://fonts.googleapis.com/css?family=Open+Sans|Roboto&display=swap);

.init{
  position: absolute;
  top: 40vh;
  width: 100%;
}
.init-welcome{
  position: absolute;
  top: 25vh;
  width: 100%;
}
.init-welcome h1{padding-bottom: 30px;}
.init-welcome p{padding-top: 30px;}

/* NAVBAR */

nav {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: rgba(0, 0, 0, 1);
  z-index: 100;
  /* box-shadow: -0px 1px 10px black; */
}
.nav-profile{
  border-radius: 50%;
  position: absolute;
  left:0;
  margin: 10px;
  width: 30px;
  cursor: pointer;
  z-index: 110;
}
.nav-logo {
  height: 50px;
  cursor: pointer;
}
.nav-buttons {
  position: absolute;
  margin-top: 3px;
  right: 0px;
}
.nav-buttons img{
  height: 25px;
  margin: 10px;
}
.nav-buttons {
  cursor: pointer;
  margin-right: 20px;
}
.logout-bin{
  position: absolute; 
  padding:5px;
  -webkit-transition: all 1s;
  transition: all 1s;
  z-index: 101;
}
.logout-icon img{
  width: 30px;
  padding: 5px;
  cursor: pointer;
}

/* NAVABR END */

/* ITEM LIST */

.item-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin-bottom: 15px;
  border-radius: 5px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  white-space: nowrap;
  font-size: 1rem;
  box-shadow: -5px 5px 10px black;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: rgba(0, 0, 0, 0.55);
}
.list-item-thumb{
  padding-left: 5px;
  height: 30px;
}
.list-item div {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.item-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  white-space: nowrap;
  font-size: 1rem;
}
.error {
  justify-content: center;
}

/* ITEM LIST END */

/* SEARCH */

.search-parent {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
}
.search-one {
  display: flex;
  flex-wrap: wrap;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  margin-bottom: 10px;
  z-index: 20;
  background-color: rgb(40, 40, 40);
}
.search-one h1 {
  width: 100%;
}
.search-ops {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
}
.ops-float {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.op-box {
  width: 100%;
  padding: 4px 5px 4px 5px;
  margin: 3px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1rem;
  background-color: rgb(34, 34, 34);
}
.active {
  background-color: rgba(0, 0, 0, 0.5);
}
.search-form {
  display: flex;
  flex: 3 1;
  align-items: center;
}
.search-form input::-webkit-input-placeholder {
  font-weight: 100;
  color: rgba(255, 255, 255, 0.513);
}
.search-form input::-moz-placeholder {
  font-weight: 100;
  color: rgba(255, 255, 255, 0.513);
}
.search-form input::-ms-input-placeholder {
  font-weight: 100;
  color: rgba(255, 255, 255, 0.513);
}
.search-form input::placeholder {
  font-weight: 100;
  color: rgba(255, 255, 255, 0.513);
}
.search-form input,
button {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: none;
  border-radius: 5px;
  outline: none;
  color: white;
}
.input-form {
  width: 90%;
  margin: 10px;
  display: flex;
  justify-content: space-evenly;
}
.input-form input {
  flex: 1 1;
  padding: 5px 5px 5px 5px;
  border-bottom: 1px solid white;
  border-radius: 0px;
  background-color: transparent;
}
.search-form button {
  padding: 5px 30px 5px 30px;
  margin-left: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

/* SEARCH END */

/* PLAYLIST */

.playlist-parent {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
}
.playlist-one {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  display: flex;
  flex-wrap: wrap;
  z-index: 20;
  background-color: rgb(40, 40, 40);
}
.playlist-one h1 {
  width: 100%;
}
.playlist-two {
  margin-bottom: 10px;
}
.playlist-pos {
  position: absolute;
  top: 15px;
}
.playlist-event img {
  position: relative;
  left: 10px;
  float: left;
  width: 20px;
  cursor: pointer;
}
.playlist-url img {
  position: relative;
  left: 10px;
  float: left;
  width: 20px;
  cursor: pointer;
}
.playlist-url form {
  position: relative;
  left: 15px;
  float: left;
}
.playlist-url input {
  width: 190px;
  outline: none;
  border: none;
  border-bottom: 1px solid white;
  color: white;
  background: transparent;
}

/* PLAYLIST END */

/* DISPLAY  */

.player-display {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 5vh;
  width: 100%;
  background-color: rgb(40, 40, 40);
}
.player-event img {
  width: 20px;
  cursor: pointer;
}
.player-frame-hide {
  display: none;
}
.player-frame {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 0px;
  overflow: hidden;
  border-bottom: 1px solid white;
}
.player-frame iframe {
  width: 100%;
}
.player-header {
  padding: 10px;
  border-bottom: 1px solid white;
}
.player-frame h2 {
  padding: 10px 40px 15px 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.player-controls{
  display: flex;
  align-items: center;
}
.player-prev {
  width: 30px;
}
.player-play {
  width: 35px;
}
.player-next {
  width: 30px;
}
.range-slider {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  height: 5px;
  outline: none;
  border-radius: 5px;
  border: 1px solid black;
  background: #d3d3d3;
}
.range-slider:hover {
  opacity: 1;
}
.range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  border-radius: 10px;
  background-color: #b60000;
  border: 1px solid white;
  cursor: pointer;
}
.seek::-webkit-slider-thumb {width:4px; background-color: black;}
  .seek {
  width: 35%;
}
.volume {
  width: 17%;
}

/* DISPLAY END  */

/* QUEUE  */

.queue {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;
}
.queue li {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  list-style: none;
}
.queue-item img {
  width: 75px;
}
.queue-item {
  width: 100%;
  margin: 2px;
  cursor: -webkit-grab;
  cursor: grab;
}
.item-button {
  color: black;
  width: 23px;
  margin-right: 4px;
  padding: 5px;
  z-index: 10;
  cursor: copy;
}
.q-button {
  color: black;
  padding: 5px 17px 5px 17px;
  z-index: 10;
  cursor: pointer;
}

/* QUEUE END */

*{
   padding: 0;
   margin: 0;
   font-family: 'Roboto', sans-serif;
}

::-webkit-scrollbar{
   background-color: transparent;
   position: relative;
   top:100px;
   height: 0;
   width: 8px;
}

::-webkit-scrollbar-thumb{
   background-color: black;
   border:1px solid rgba(255, 255, 255, .2);
   border-radius: 5px;
}

body {
   margin: 0;
   font-family: 'Open Sans', sans-serif;
   background-color: rgb(30, 30, 30);
} 

h1{
   color: white;
   margin: 10px;
   padding-bottom: 15px;
   border-bottom: 1px solid white;
   font-size:1.3rem;
   -webkit-user-select: none;
      -moz-user-select: none;
       -ms-user-select: none;
           user-select: none;
}

/* APP */

.App {
   text-align: center;
   color: white;
}
.Main{
   display: flex;
   flex-direction: column;
   position: fixed;
   left: 50%;
   -webkit-transform: translate(-50%);
           transform: translate(-50%);
   width: 100%;
   min-width: 400px;
   max-width: 750px;
   height: 100%;
}
.log-box{
   position: absolute;
   top: 40%;
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
}
.login-button{
   background-color: rgb(255, 255, 255);
}

/* APP END */

/* SECTIONS */

.section{
   margin: 5px 10px 5px 10px;
   max-height: 60%;
   border: 1px solid black;
   background-color: rgb(40, 40, 40);
}
.nav{
   max-height: 0px;
   margin-bottom: 50px;
}
.search{
   mIN-height: 12vh; 
   overflow: scroll;
}
.playlist{
   overflow: scroll;
}
.player{
   max-height: 5vh; 
   min-height: 5vh;
   overflow: hidden;
}
.queue{
   padding: 2px 0px 0px 2px;
   min-height: 4vh;  
}
/* SECTIONS END */
