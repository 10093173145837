@import url('https://fonts.googleapis.com/css?family=Open+Sans|Roboto&display=swap');

*{
   padding: 0;
   margin: 0;
   font-family: 'Roboto', sans-serif;
}

::-webkit-scrollbar{
   background-color: transparent;
   position: relative;
   top:100px;
   height: 0;
   width: 8px;
}

::-webkit-scrollbar-thumb{
   background-color: black;
   border:1px solid rgba(255, 255, 255, .2);
   border-radius: 5px;
}

body {
   margin: 0;
   font-family: 'Open Sans', sans-serif;
   background-color: rgb(30, 30, 30);
} 

h1{
   color: white;
   margin: 10px;
   padding-bottom: 15px;
   border-bottom: 1px solid white;
   font-size:1.3rem;
   user-select: none;
}

/* APP */

.App {
   text-align: center;
   color: white;
}
.Main{
   display: flex;
   flex-direction: column;
   position: fixed;
   left: 50%;
   transform: translate(-50%);
   width: 100%;
   min-width: 400px;
   max-width: 750px;
   height: 100%;
}
.log-box{
   position: absolute;
   top: 40%;
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
}
.login-button{
   background-color: rgb(255, 255, 255);
}

/* APP END */

/* SECTIONS */

.section{
   margin: 5px 10px 5px 10px;
   max-height: 60%;
   border: 1px solid black;
   background-color: rgb(40, 40, 40);
}
.nav{
   max-height: 0px;
   margin-bottom: 50px;
}
.search{
   mIN-height: 12vh; 
   overflow: scroll;
}
.playlist{
   overflow: scroll;
}
.player{
   max-height: 5vh; 
   min-height: 5vh;
   overflow: hidden;
}
.queue{
   padding: 2px 0px 0px 2px;
   min-height: 4vh;  
}
/* SECTIONS END */